<template>
    <div v-if="page">
        <h2>{{ $t('dashboard-title') }}</h2>
        <div v-if="page.doc" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>



        <div class="col-12" style="padding-right: 0px; padding-bottom: 10px;">
            <div class="card">
                <div class="card-body" style="text-align: center;">
                    <span>
                        <h2>VIP Barcelona 2026</h2>
                        <div class="info-box">
                            <div class="info-box">
                                <div class="info-box">
                                    <div class="info-box">
                                        <table style="width: 100%;">
                                            <tr>
                                                <td style="width: 72px;padding-left: 5px;padding-right: 5px;">{{
                                                    page.viewer.vip.value.toFixed(2) }} €</td>
                                                <td style="width: calc(100% - 144px );">
                                                    <b-progress :max="100" height="2rem">
                                                        <b-progress-bar :value="page.viewer.vip.percent"
                                                            style="background-color: #28a745 !important;"><!-- -->
                                                            <span>{{ page.viewer.vip.percent }}%</span>
                                                        </b-progress-bar>
                                                    </b-progress>
                                                </td>
                                                <td style="width: 72px;padding-left: 5px;padding-right: 5px;">60000 €</td>
                                            </tr>
                                        </table>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>


        <v-alert :value="true" color="success" v-if="page.viewer.totals.fastStartRemaining > 0" class="mb-3">{{
            $t('fast-start') }}: {{ (page.viewer.totals.fastStartRemaining / 864e5).toFixed(0) }} days</v-alert>
        <div class="">
            <token-list page="dashboard" :token-list.sync="page.viewer.totals"
                :daily-loyalty.sync="page.viewer.dailyLoyalty" :dailyLoyaltyUSDT.sync="page.viewer.dailyLoyaltyUSDT" />
        </div>

        <div class="row">
            <div class="col-lg-6"><freedom-portfolio :freedom-portfolio.sync="page.freedomPortfolio"></freedom-portfolio>
            </div>
            <rank v-if="page.rankProgress" :progress.sync="page.rankProgress" :rank.sync="page.viewer.totals.rank"
                :username.sync="page.viewer.username" :max-rank.sync="page.viewer.totals.maxRank"
                :volume.sync="page.viewer.quaterlyVolume / 1e4" :degraded.sync="page.viewer.totals.degraded" />
        </div>
        <div class="row">
            <div class="col-12">
                <affiliate class="mb-3" :invite-id="page.viewer.inviteId" :leg.sync="page.viewer.inviteLeg" />
            </div>
        </div>
        <div class="row">
            <charts :chart-data="chartData" />
        </div>
    </div>
</template>

<script>
import TokenList from "../components/Dashboard/TokenList";
import Affiliate from "@/components/Profile/Affiliate";
import Charts from "@/components/Dashboard/Charts";
import Rank from "@/components/Dashboard/Rank";
import FreedomPortfolio from "@/components/Dashboard/FreedomPortfolio";
import gql from 'graphql-tag'
import PageTitle from "@/components/common/PageTitle";
import { BProgress, BProgressBar } from 'bootstrap-vue'

export default {
    name: "Dashboard",
    components: {
        TokenList,
        Affiliate,
        Charts,
        PageTitle,
        Rank,
        FreedomPortfolio
    },
    data() {
        return {
            heading: "",
            subheading:
                "",
            icon: "pe-7s-plane icon-gradient bg-plum-plate",
            page: null,
            reservations: null,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: true,
                    shadowColor: '#000000',
                    fontSize: 20,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#2dbd2d',
                    backgroundColor: '#333333',
                    inverted: false
                },
                layout: {
                    height: 20,
                    width: 100,
                    verticalTextAlign: 75,
                    horizontalTextAlign: 3,
                    zeroOffset: 0,
                    strokeWidth: 10,
                    progressPadding: 0,
                    type: 'line'
                }
            },
        }
    },
    mounted() {
        if (sessionStorage.getItem('afterLogin') === "1") {
            sessionStorage.setItem('afterLogin', "0")
        }
        if (localStorage.getItem('mimic-token')) {
            sessionStorage.setItem('apollo-token', localStorage.getItem('mimic-token'))
            localStorage.removeItem('mimic-token')
            this.$forceUpdate()
        }

    },
    methods: {},
    apollo: {
        page: {
            query: gql`query page($id:ID!) {
                    doc(id:$id){id,content,title},
                    viewer{id,username,binaryDepth,inviteId,inviteLeg,dailyLoyalty,dailyLoyaltyUSDT ,quaterlyVolume,
                        totals{tokens,usdt,inactive,shares,sharesA,promotionalSharesA,loyalty,held,packages,freedom,rank,degraded,totalLoyalties,totalLoyaltiesUSDT,fastStartRemaining,maxRank,cummulatedDividends,},
                        country,
                        vip{value,percent}
                    },
                    viewerIsAdmin,
                    rankProgress{rank,nextRank,downlineRank,progress,maxProgress},
                    freedomPortfolio{level, levelLimit, levelName,nextLevel,nextLevelLimit,nextLevelName,shares, percentage}
                    promotionProgress{reward,nextReward,progress,maxProgress,rewardImages,rewardLimits},
                    priceHistory{price{x,y}}
                    }
                `,
            variables: {
                id: "dashboard"
            },
            update: data => data,
            result({ data }) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }

    },
    computed: {
        chartData() {
            return [{
                name: "HTL/EUR",
                data: this.page.priceHistory.price.map(a => [new Date(a.x * 24 * 60 * 60 * 1000).getTime(), a.y])
            }]
        },
    },
    created() {
    }
}
</script>

<style>
.chart-btn {
    font-size: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.progressbar {
    background-color: #2dbd2d;
}
</style>